import React, { useContext } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Link } from '@mui/material'
import languages from '../data/languages'
import { LanguageContext } from '../providers/LanguageProvider'

const LanguageSelector = ({ mobile = false }: { mobile?: boolean }) => {
  const { lang } = useContext(LanguageContext)
  const { pathname } = useLocation()

  return (
    <>
      {languages.map(({ value, label }) => (
        <Link
          key={value}
          component={NavLink}
          to={pathname.replace(lang, value)}
          sx={{
            textTransform: 'uppercase',
            fontSize: mobile ? 14 : 12,
            textDecoration: 'none',
            color: '#555',
            '&:hover': { color: '#000' },
            '&.active': { fontWeight: 'bold', color: '#000' },
            ...(mobile && { padding: '6px 8px' }),
          }}
        >
          {label.slice(0, 3)}
        </Link>
      ))}
    </>
  )
}

export default LanguageSelector
