import { Box, CircularProgress } from '@mui/material'
import { keyframes } from '@mui/system'

const spin = keyframes`
  from {
    color: #FFD500;
  }
  48% {
    color: #FFD500;
  }
  50% {
    color: #005BBB;
  }
  98% {
    color: #005BBB;
  }
  to {
    color: #FFD500;
  }
`

const Spinner = () => (
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      margin: '-1em 0 0 -1em',
      animation: `${spin} 2.8s infinite linear`,
    }}
  >
    <CircularProgress color="inherit" />
  </Box>
)

export default Spinner
