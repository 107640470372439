import { Language } from '../types/Language.type'

export const title = {
  [Language.EN]: 'Current sanctions and restrictions against Russia for the attack on Ukraine',
  [Language.UK]: 'Актуальні санкції та обмеження проти Росії за напад на Україну',
  [Language.RU]: 'Актуальные санкции и ограничения против России за нападение на Украину',
}

export const description = {
  [Language.EN]:
    'Interactive map of all restrictions imposed by the countries of the world against Russia since the start of its war with Ukraine',
  [Language.UK]:
    'Інтерактивна карта всіх обмежень, що вводяться країнами світу проти Росії з моменту початку її війни з Україною',
  [Language.RU]:
    'Интерактивная карта всех ограничений, вводимых странами мира против России с момента начала ее войны с Украиной',
}
