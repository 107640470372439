import * as React from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import Menu from './Menu'
import Logo from './Logo'
import Timer from './Timer'
import LanguageSelector from './LanguageSelector'
import { AppBar, Box, IconButton, Toolbar } from '@mui/material'

const Header = ({ openDrawer }: { openDrawer: () => void }) => {
  return (
    <AppBar position="static" sx={{ background: '#eee', position: 'relative', zIndex: 99 }}>
      <Toolbar disableGutters>
        <Box sx={{ display: { xs: 'flex', md: 'none' }, color: '#333' }}>
          <IconButton size="large" color="inherit" onClick={openDrawer}>
            <MenuIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            flexShrink: 0,
            display: { xs: 'flex', md: 'none' },
          }}
        >
          <Logo mobile={true} />
        </Box>

        <Box
          sx={{
            flexShrink: 0,
            display: { xs: 'none', md: 'flex' },
            margin: '0 15px',
          }}
        >
          <Logo />
        </Box>

        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <Menu />
        </Box>

        <Box sx={{ flexGrow: 0, margin: '0 15px' }}>
          <Timer />
        </Box>

        <Box
          sx={{
            flexGrow: 0,
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            marginRight: '15px',
          }}
        >
          <LanguageSelector />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Header
