import languages from '../data/languages'
import { Language } from '../types/Language.type'

const supported = (lang: string | null) => lang && languages.find((language) => language.value === lang)

const getLang = () => {
  let lang: string | null = null

  try {
    lang = window.location.pathname.split('/')[1]
    if (!supported(lang)) {
      lang = null
    }
  } catch {}

  if (!lang) {
    lang = localStorage.getItem('lang')
    if (!supported(lang)) {
      lang = null
    }
  }

  if (!lang) {
    const { navigator } = window
    if (navigator) {
      const { language, languages } = navigator
      lang = language || (languages && languages.length && languages[0]) || null
      if (lang && lang.length > 2) lang = lang.substring(0, 2)
      if (!supported(lang)) {
        lang = null
      }
    }
  }

  if (!lang) {
    lang = languages[0].value
  }

  try {
    localStorage.setItem('lang', lang)
  } catch {}

  return lang as Language
}

export default getLang
