import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './index.css'

Sentry.init({
  dsn: 'https://b5362d60eb4a4233af5e0417950dbf85@o1126693.ingest.sentry.io/6250713',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  beforeSend: (event) => {
    if (window.location.hostname === 'localhost') {
      return null
    }
    return event
  },
})

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals() // TODO

/* TODO
SEO, covers, btn colors
chunk load error - reload
profile react render
fix tests
check recent updates in csvs and covers
Заборонити - Заборона
px to em
div to Box
style to sx
h2 to typography?
fix map title & other titles
fix map tooltip fonts
Add search + A-Z companies
Merge EU, add flags icons on countries?
Estonian domestic sanctions against Yandex.Taxi
Hyatt hotels, which manages high-class hotel chains, breaks contracts and ceases cooperation with the Hyatt Regency Moscow Petrovsky Park hotel in Moscow
retail and wholesale chain Coop - Transgourmet - has announced its withdrawal from business in Russia
Electronic Arts banned russian gamers - sports?, REMOVE Steam?, VR Group from transport to companies
San Marino joined,The Central Bank of the Bahamas,Georgian banks do Mir cards,Coinbase Blocks 25,000 crypto wallets,Golden Sachs Investment Bank leaves
4th package https://www.consilium.europa.eu/en/press/press-releases/2022/03/15/russia-s-military-aggression-against-ukraine-fourth-eu-package-of-sectoral-and-individual-measures/
check all visas and all transport
беларусь?
 */
