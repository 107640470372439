import React, { useContext } from 'react'
import { LanguageContext } from '../providers/LanguageProvider'
import { Helmet } from 'react-helmet'
import * as meta from '../data/meta'
import { Language } from '../types/Language.type'
import { useLocation } from 'react-router-dom'

const Meta = () => {
  const { pathname } = useLocation()
  const { lang } = useContext(LanguageContext)

  const title = meta.title[lang]
  const description = meta.description[lang]

  // TODO new
  // const title = {
  //   [Language.EN]: 'War at Ukraine: All sanctions and restrictions put on Russia',
  //   [Language.UK]: 'Війна в Україні: Всі санкції та обмеження накладені на Росію',
  //   [Language.RU]: 'Война в Украине: Все санкции и ограничения наложенные на Россию',
  // }[lang]

  // TODO is canonical current or english version?
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={`https://www.putinisolation.com${pathname}`} />
      {Object.values(Language).map((l) => (
        <link
          rel="alternate"
          href={`https://www.putinisolation.com${pathname.replace(lang, l)}`}
          hrefLang={l}
          key={l}
        />
      ))}
    </Helmet>
  )
}

export default Meta
