import { useCallback, useEffect, useMemo, useState } from 'react'
import { useInterval } from 'usehooks-ts'

const DEFAULT_DELAY = 15000

const regionMap = {
  1: 'Vinnytsia',
  2: 'Volyn',
  3: 'Dnipropetrovsk',
  4: 'Donetsk',
  5: 'Zhytomyr',
  6: 'Zakarpattia',
  7: 'Zaporizhzhia',
  8: 'Ivano-Frankivsk',
  9: 'Kyiv',
  10: 'Kirovohrad',
  11: 'Luhansk',
  12: 'Lviv',
  13: 'Mykolaiv',
  14: 'Odessa', // Odesa?
  15: 'Poltava',
  16: 'Rivne',
  17: 'Sumy',
  18: 'Ternopil',
  19: 'Kharkiv',
  20: 'Kherson',
  21: 'Khmelnytskyi',
  22: 'Cherkasy',
  23: 'Chernivtsi',
  24: 'Chernihiv',
  25: 'Kyiv city',
}

interface AlertData {
  name: string
  value: number
  itemStyle?: {
    areaColor: string
  }
}

const useGetAlerts = () => {
  const [delay, setDelay] = useState(DEFAULT_DELAY)
  const [data, setData] = useState<AlertData[]>([])
  const [newData, setNewData] = useState<AlertData[]>([])
  const oldDataStr = useMemo(() => JSON.stringify(data), [data])

  const getData = useCallback(async () => {
    const response = await fetch('https://alerts.com.ua/api/states', {
      headers: {
        // 'Cache-Control': 'no-cache',
        'X-API-Key': 'ce7845a44457a31e1f4da962867ac8c8136b16a3',
      },
    })

    const raw = await response.json()

    setNewData(
      raw.states.map((state: any) => ({
        name: regionMap[String(state.id) as unknown as keyof typeof regionMap],
        value: state.alert ? 1 : 0,
        ...(state.alert && {
          itemStyle: {
            areaColor: '#ff0000',
          },
        }),
      })),
    )
  }, [])

  useEffect(() => {
    if (JSON.stringify(newData) !== oldDataStr) {
      setData(newData)
    }
  }, [newData, oldDataStr])

  useEffect(() => {
    getData().catch(() => setDelay(1000))
  }, [getData])

  useInterval(
    () =>
      getData()
        .then(() => setDelay(DEFAULT_DELAY))
        .catch(() => setDelay(DEFAULT_DELAY + Math.round(Math.random() * 6 - 3) * 1000)),
    delay,
  )

  const alertCount = useMemo(() => data.reduce((sum, { value }) => sum + value, 0), [data])

  return { data, alertCount }
}

export default useGetAlerts
