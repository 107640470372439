import React, { Suspense } from 'react'
import { LanguageProvider } from './providers/LanguageProvider'
import Router from './components/Router'
import Spinner from './components/Spinner'
import { Box } from '@mui/material'
import Auxiliary from './components/Auxiliary'

function App() {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <LanguageProvider>
        <Auxiliary />
        <Suspense fallback={<Spinner />}>
          <Router />
        </Suspense>
      </LanguageProvider>
    </Box>
  )
}

export default App
