import { logEvent } from 'firebase/analytics'
import analytics from '../firebase/analytics'

// TODO plain event names without value
export const track = (event_name: string, value: string) => {
  logEvent(analytics, event_name, { value })
}

export const trackSubmitForm = (content_type: string, value: boolean) => {
  logEvent(analytics, 'submit_form', {
    content_type,
    value,
  })
}
