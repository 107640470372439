import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyBPpfh3V-SlBqk9kFH1yKpymLz4JX_0WAs',
  authDomain: 'putinisolation.firebaseapp.com',
  projectId: 'putinisolation',
  storageBucket: 'putinisolation.appspot.com',
  messagingSenderId: '187788956384',
  appId: '1:187788956384:web:f08146c098aefbe9fd7685',
}

const firebaseApp = initializeApp(firebaseConfig)

export default firebaseApp
