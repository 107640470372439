import * as React from 'react'
import { Box, Drawer } from '@mui/material'
import Menu from './Menu'
import LanguageSelector from './LanguageSelector'

const MobileMenu = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  // TODO as <List> w/ icons
  return (
    <Drawer open={open} onClose={onClose} onClick={onClose}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          width: 200,
          padding: 4,
        }}
      >
        <Menu mobile={true} />
      </Box>
      <div style={{ flexGrow: 1 }} />
      <Box
        sx={{
          padding: '30px',
          width: 200,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <LanguageSelector mobile={true} />
      </Box>
    </Drawer>
  )
}
export default MobileMenu
