import * as React from 'react'
import { Link as RLink } from 'react-router-dom'
import { Link } from '@mui/material'
import { track } from '../helpers/trackers'

const Logo = ({ mobile = false }: { mobile?: boolean }) => {
  return (
    <Link
      to="/"
      component={RLink}
      sx={{
        textTransform: 'uppercase',
        textDecoration: 'none',
        fontSize: mobile ? 14 : 12,
        padding: '4px 8px 2px',
        borderRadius: '10px',
        border: '1px dashed #333',
        color: '#333',
        '&:hover': { color: '#000', borderColor: '#000' },
      }}
      onClick={() => track('logo_click', 'true')}
    >
      <h1
        style={{
          display: 'flex',
          flexDirection: mobile ? 'row' : 'column',
          fontSize: 'inherit',
          margin: 0,
        }}
      >
        <div style={{ marginBottom: mobile ? 0 : -7 }}>Putin</div>
        <div style={{ marginLeft: mobile ? 5 : 0 }}>isolation</div>
      </h1>
    </Link>
  )
}

export default Logo
