import React, { Suspense, useCallback, useState } from 'react'
import Meta from './Meta'
import { CssBaseline } from '@mui/material'
import Header from './Header'
import MobileMenu from './MobileMenu'
import { track } from '../helpers/trackers'

const External = React.lazy(() => import('./External'))
const Share = React.lazy(() => import('./Share'))

const Auxiliary = () => {
  const [drawerOpen, setDrawerOpen] = useState(false)

  const openDrawer = useCallback(() => {
    setDrawerOpen(true)
    track('drawer_open', 'true')
  }, [])
  const closeDrawer = useCallback(() => {
    setDrawerOpen(false)
    track('drawer_close', 'true')
  }, [])

  return (
    <>
      <Meta />
      <CssBaseline />
      <Header openDrawer={openDrawer} />
      <MobileMenu open={drawerOpen} onClose={closeDrawer} />
      <Suspense fallback={null}>
        <External />
        <Share />
      </Suspense>
    </>
  )
}

export default Auxiliary
