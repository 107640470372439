import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { LanguageContext } from '../providers/LanguageProvider'
import { enGB as en, ru, uk } from 'date-fns/locale'
import { formatDistanceToNowStrict, formatDuration, intervalToDuration } from 'date-fns'
import { Language } from '../types/Language.type'
import { Box, Tooltip } from '@mui/material'

const WAR_START = new Date('2022-02-24T05:00:00+02:00')

const Timer = () => {
  const { lang } = useContext(LanguageContext)

  const prefix = {
    [Language.EN]: `Russia invaded Ukraine`,
    [Language.UK]: `Росія напала на Україну`,
    [Language.RU]: `Россия вторглась в Украину`,
  }[lang]

  const suffix = {
    [Language.EN]: `ago`,
    [Language.UK]: `тому`,
    [Language.RU]: `назад`,
  }[lang]

  const warStart = {
    [Language.EN]: `War started on Thursday, 24 February 2022 at 5 AM`,
    [Language.UK]: `Війна почалася в четвер, 24-е лютого 2022 р. о 5:00`,
    [Language.RU]: `Война началась в четверг, 24 февраля 2022 г., 05:00`,
  }[lang]

  const locale = useMemo(() => ({ en, uk, ru }[lang]), [lang])

  const getTimer = useCallback(
    () =>
      (
        formatDistanceToNowStrict(WAR_START, {
          unit: 'day',
          roundingMethod: 'floor',
          locale,
        }) +
        ' ' +
        formatDuration(intervalToDuration({ start: WAR_START, end: new Date() }), { locale, format: ['hours'] })
      ).replace('дня', lang === Language.UK ? 'дні' : 'дня') +
      ' ' +
      suffix,
    [locale, lang, suffix],
  )

  const [timer, setTimer] = useState(getTimer())

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimer(getTimer())
    }, 1000)

    return () => clearTimeout(timer)
  })

  useEffect(() => setTimer(getTimer()), [getTimer])

  return (
    <Tooltip title={warStart}>
      <Box
        sx={{
          color: '#da291c',
          textAlign: 'center',
          whiteSpace: 'nowrap',
          cursor: 'default',
        }}
      >
        <h2
          style={{
            margin: '2px 0 -5px',
            textTransform: 'uppercase',
            fontSize: 'calc(6px + 1vmin)',
          }}
        >
          {prefix}
        </h2>
        <time
          style={{
            fontSize: 'calc(8px + 1vmin)',
          }}
        >
          {timer}
        </time>
      </Box>
    </Tooltip>
  )
}

export default Timer
