import React, { useContext } from 'react'
import { LanguageContext } from '../providers/LanguageProvider'
import { NavLink } from 'react-router-dom'
import { Language } from '../types/Language.type'
import { Button } from '@mui/material'
import CampaignIcon from '@mui/icons-material/Campaign'
import useSharedAlerts from '../hooks/useSharedAlerts'
import { keyframes } from '@mui/system'

const menu = [
  {
    path: '/sanctions',
    [Language.EN]: 'Sanctions',
    [Language.UK]: 'Санкції',
    [Language.RU]: 'Санкции',
  },
  {
    path: '/transport',
    [Language.EN]: 'Transport',
    [Language.UK]: 'Транспорт',
    [Language.RU]: 'Транспорт',
  },
  {
    path: '/nato',
    [Language.EN]: 'NATO',
    [Language.UK]: 'НАТО',
    [Language.RU]: 'НАТО',
  },
  {
    path: '/court',
    [Language.EN]: 'Court',
    [Language.UK]: 'Суд',
    [Language.RU]: 'Суд',
  },
  {
    path: '/companies',
    [Language.EN]: 'Companies',
    [Language.UK]: 'Компанії',
    [Language.RU]: 'Компании',
  },
  {
    path: '/sports',
    [Language.EN]: 'Sports',
    [Language.UK]: 'Спорт',
    [Language.RU]: 'Спорт',
  },
  {
    splitter: true,
  },
  {
    icon: <CampaignIcon />,
    path: '/alerts',
    [Language.EN]: 'Alerts',
    [Language.UK]: 'Тривоги',
    [Language.RU]: 'Тревоги',
  },
  // {
  //   path: '/losses',
  //   [Language.EN]: 'Losses',
  //   [Language.UK]: 'Втрати',
  //   [Language.RU]: 'Потери',
  // },
  // {
  //   path: '/help',
  //   [Language.EN]: 'Help',
  //   [Language.UK]: 'Допомога',
  //   [Language.RU]: 'Помощь',
  // },
]

// TODO single red color
const glow = keyframes`
  from {
    color: rgba(255, 0, 0, 1);
  }
  50% {
    color: rgba(255, 0, 0, 0.1);
  }
  to {
    color: rgba(255, 0, 0, 1);
  }
`

const Menu = ({ mobile = false }: { mobile?: boolean }) => {
  const { lang } = useContext(LanguageContext)

  const { alertCount } = useSharedAlerts()

  return (
    <>
      {menu
        .map((item) => ({
          ...item,
          text: item[lang],
        }))
        .map(({ icon, path, text, splitter }) =>
          splitter ? (
            <div
              key="splitter"
              style={
                mobile
                  ? {
                      borderTop: '1px dashed #555',
                      margin: '10px 0',
                      width: '100%',
                    }
                  : {
                      borderLeft: '1px dashed #555',
                      margin: '0 10px',
                    }
              }
            />
          ) : (
            <Button
              key={path}
              startIcon={icon}
              component={NavLink}
              to={`/${lang}${path}/`}
              sx={{
                color: '#333',
                '&:hover': {
                  color: 'black',
                  background: 'rgba(255,255,255,0.3)',
                },
                '&.active': { fontWeight: 'bold' },
                ...(path === '/alerts' && {
                  svg: alertCount > 1 ? { animation: `${glow} 3s infinite` } : { color: 'rgba(0,0,0,0.5)' },
                }),
                ...(mobile && {
                  justifyContent: 'start',
                }),
              }}
            >
              {text}
            </Button>
          ),
        )}
    </>
  )
}

export default Menu
