import React, { createContext, FC, useEffect, useState } from 'react'
import getLang from '../helpers/getLang'
import { Language } from '../types/Language.type'
import { setUserProperties } from 'firebase/analytics'
import { useLocation } from 'react-router-dom'
import analytics from '../firebase/analytics'

export const LanguageContext = createContext<{
  lang: Language
  setLang: (lang: Language) => void
}>({
  lang: Language.EN,
  setLang: (lang: Language) => {},
})

export const LanguageProvider: FC = ({ children }) => {
  const [state, setState] = useState(getLang())
  const { pathname } = useLocation()

  useEffect(() => {
    setState(getLang())
  }, [pathname])

  useEffect(() => {
    setUserProperties(analytics, { language: state })
  }, [state])

  const value = {
    lang: state,
    setLang: (lang: Language) => {
      try {
        localStorage.setItem('lang', lang)
      } catch {}
      setState(lang)
    },
  }

  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>
}
