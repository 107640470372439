import React, { useContext, useMemo } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { LanguageContext } from '../providers/LanguageProvider'
import { Language } from '../types/Language.type'

const Sanctions = React.lazy(() => import('../pages/Sanctions'))
const Transport = React.lazy(() => import('../pages/Transport'))
const Nato = React.lazy(() => import('../pages/Nato'))
const Court = React.lazy(() => import('../pages/Court'))
const Companies = React.lazy(() => import('../pages/Companies'))
const Sports = React.lazy(() => import('../pages/Sports'))
const Alerts = React.lazy(() => import('../pages/Alerts'))

const multiLangRoute = (route: string) => Object.values(Language).map((lang) => `/${lang}${route}/`)
const mainRoutes = multiLangRoute('/sanctions')

const Router = () => {
  const { lang } = useContext(LanguageContext)

  const mainRoute = useMemo(
    () => mainRoutes.find((route) => route.indexOf(`/${lang}/`) !== -1) || mainRoutes[0],
    [lang],
  )

  return (
    <Routes>
      {mainRoutes.map((path) => (
        <Route key={path} path={path} element={<Sanctions />} />
      ))}
      {multiLangRoute('/transport').map((path) => (
        <Route key={path} path={path} element={<Transport />} />
      ))}
      {multiLangRoute('/nato').map((path) => (
        <Route key={path} path={path} element={<Nato />} />
      ))}
      {multiLangRoute('/court').map((path) => (
        <Route key={path} path={path} element={<Court />} />
      ))}
      {multiLangRoute('/companies').map((path) => (
        <Route key={path} path={path} element={<Companies />} />
      ))}
      {multiLangRoute('/sports').map((path) => (
        <Route key={path} path={path} element={<Sports />} />
      ))}
      {multiLangRoute('/alerts').map((path) => (
        <Route key={path} path={path} element={<Alerts />} />
      ))}
      {/* TODO add losses badge */}
      {/*{multiLangRoute('/losses').map(path => <Route key={path} path={path} element={<Losses/>}/>)}*/}
      {/*{multiLangRoute('/help').map(path => <Route key={path} path={path} element={<Help/>}/>)}*/}
      <Route path="*" element={<Navigate to={mainRoute} replace={true} />} />
    </Routes>
  )
}

export default Router
